import { environment } from 'src/environments/environment';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ServicesService } from '../../services/services.service';
import { GroupForPublicViewModel, ServiceViewModel, ServiceType, CustomBasedServiceType } from '../../model/services.model';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/common/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

declare function initActive(): any;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit, AfterViewInit {

  @Input() hideHearder: boolean;
  @Input() showTrendingServices: boolean;
  @Input() isHomePage: boolean;

  @ViewChild("divTrindingServices") divTrindingServices: ElementRef;

  ServiceType = ServiceType;

  trendingServices: ServiceViewModel[];

  isRTL: boolean;
  isClosed: boolean;
  viewHeight: number;

  get isLoading(): boolean {
    return this.servicesService.isLoadingGroups;
  }

  get showServicesInGroup(): boolean {
    return this.servicesService.showServicesInGroup;
  }

  get serviceGroups(): GroupForPublicViewModel[] {
    return this.servicesService.serviceGroups;
  }

  get services(): ServiceViewModel[] {
    return this.servicesService.services;
  }

  get selectedServiceGroup(): GroupForPublicViewModel {
    return this.servicesService.selectedServiceGroup;
  }

  set selectedServiceGroup(obj: GroupForPublicViewModel) {
    this.servicesService.selectedServiceGroup = obj;
    if (!this.isClosed)
      this.servicesToogle();
  }

  get selectedService(): ServiceViewModel {
    return this.servicesService.selectedService;
  }

  set selectedService(obj: ServiceViewModel) {
    this.servicesService.selectedService = obj;
  }

  constructor(private servicesService: ServicesService,
    private authService: AuthService,
    private translate: TranslateService,
    public router: Router) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.servicesToogle();
    }, 800);

    if (!this.hideHearder) {
      initActive();
    }
  }

  ngOnInit() {

    if (this.translate.currentLang == 'en') {
      this.isRTL = false;
    } else {
      this.isRTL = true;
    }
    this.servicesService.getAllGroups();
    if (this.showTrendingServices) {
      this.getTrendingServices();
    }
  }

  //#region Groups And Services

  getTrendingServices() {
    this.trendingServices = [];
    this.servicesService.trendingServices().subscribe(res => {
      this.trendingServices = res;
      let index = 1;
      this.trendingServices.forEach((service) => {
        this.getServiceIcon(service);
        service.delay = index * 100 + "ms";
        index = index + 1;
      });
    }, err => {
      console.log(err);
    })
  }

  showServiceDetails(service: ServiceViewModel) {
    debugger;
    this.selectedService = service;
    this.servicesService.showServiceAfterLogin = false;
    this.servicesService.showServiceDetails(this.authService.isAuthenticated());
  }

  servicesToogle() {
    if (!this.divTrindingServices || !this.divTrindingServices.nativeElement)
      return;
    this.viewHeight = 0;
    this.isClosed = !this.isClosed;
    if (this.isClosed)
      this.viewHeight = -1 * (this.divTrindingServices.nativeElement.offsetHeight - 0);
    else
      this.servicesService.selectedServiceGroup = null;
  }

  private getServiceIcon(service: ServiceViewModel) {
    this.servicesService.getBase64Image(service.iconId).subscribe((res) => {
      service.icon = res;
    });
  }

  //#endregion
}
