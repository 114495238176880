<!-- ##### Start Slides  ##### -->
<section *ngIf="!hideHearder" class="breadcumb-area bg-img"
  style="background-image: url(assets/images/banners/services.jpg);">
</section>
<!-- ##### End Slides ##### -->
<!-- <div class="sharethis-inline-share-buttons"></div> -->

<!-- ##### Start Address of Electronic Services ##### -->
<div *ngIf="!hideHearder" class="south-search-area article">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="advanced-search-form">
          <div class="search-title service-title">
            <div>
              <h5> {{'HEADER.SERVICES' | translate}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="team-area section-gap article" *ngIf="isHomePage">
  <div class="container">
    <div class="row justify-content-center d-flex align-items-center mt-5" *ngIf="isLoading">
      <div class="col-12 d-flex flex-wrap justify-content-center mb-5 py-5">
        <div class="spinner w-100 text-center">
          <i class="fa fa-spinner fa-spin fa-2x"></i>
        </div>
      </div>
    </div>
    <div class="row justify-content-center d-flex align-items-center"
      *ngIf="showServicesInGroup && selectedServiceGroup == null">
      <div tabindex="0" class="col-lg-2 col-md-6 single-team wow group-item fadeInUp d-flex flex-column align-items-center w-serv"
        [attr.data-wow-delay]="serviceGroup.delay" (click)="selectedServiceGroup = serviceGroup;" (keyup.enter)="selectedServiceGroup = serviceGroup;"
        *ngFor="let serviceGroup of serviceGroups;">
        <div *ngIf="serviceGroup.icon != null" class="thumb">
          <a hrefX="#" onclick="javascript:;">
            <img *ngIf="serviceGroup.icon != null" [src]="serviceGroup.icon | safeUrl" alt="{{serviceGroup.title}}" class="bgserv2" href="#" onclick="javascript:;" >
          </a>
        </div>
        <div>
          <h6 class="title">{{serviceGroup.title}}</h6>
        </div>
      </div>
    </div>
    <div class="row justify-content-between d-flex align-items-center arrow-sev"
      *ngIf="showServicesInGroup && selectedServiceGroup != null">
      <div 
        class="col-lg-6 col-md-6 single-team selected-group-item fadeInUp d-flex align-items-center justify-content-start">
        <div *ngIf="selectedServiceGroup.icon != null" class="thumb">
          <img *ngIf="selectedServiceGroup.icon != null" [src]="selectedServiceGroup.icon | safeUrl" alt=""
            class="bgserv2">
        </div>
        <div class="mx-4">
          <h6 class="title">{{selectedServiceGroup.title}}</h6>
        </div>
      </div>
      <i class="fas back-to-list" [ngClass]="{'fa-chevron-right': isRTL, 'fa-chevron-left': !isRTL}"
        (click)="selectedServiceGroup = null;"></i>
    </div>
    <div class="row justify-content-center d-flex bg-sub-serv wow fadeInUp" data-wow-delay="300ms"
      *ngIf="showServicesInGroup && selectedServiceGroup != null">
      <div class="col-lg-3 col-md-6 single-team d-flex flex-column w-serv wow fadeInUp"
        [attr.data-wow-delay]="service.delay" *ngFor="let service of selectedServiceGroup.services;">
        <div *ngIf="service.type != ServiceType.URL" class="thumb serv-bg" (click)="showServiceDetails(service)" (keyup.enter)="showServiceDetails(service)">
          <img *ngIf="service.icon != null" [src]="service.icon | safeUrl" alt="" style="height: 95%;">
        </div>
        <a *ngIf="service.type == ServiceType.URL" class="thumb serv-bg" href="{{service.url}}" target="_blank">
          <img *ngIf="service.icon != null" [src]="service.icon | safeUrl" alt="" style="height: 95%;">
        </a>
        <a tabindex="0" *ngIf="service.type == ServiceType.URL" class="custom-link" href="{{service.url}}" target="_blank">
          <h6 class="title">{{service.title}}</h6>
        </a>
        <div tabindex="0" *ngIf="service.type != ServiceType.URL" class="hand" (click)="showServiceDetails(service)" (keyup.enter)="showServiceDetails(service)">
          <h6 class="title">{{service.title}}</h6>
        </div>
      </div>
    </div>
    <div class="row justify-content-center d-flex wow fadeInUp" data-wow-delay="300ms" *ngIf="!showServicesInGroup">
      <div class="col-lg-3 col-md-6 single-team d-flex flex-column w-serv wow fadeInUp"
        [attr.data-wow-delay]="service.delay" *ngFor="let service of services;">
        <div *ngIf="service.type != ServiceType.URL" class="thumb serv-bg" (click)="showServiceDetails(service)" (keyup.enter)="showServiceDetails(service)">
          <img *ngIf="service.icon != null" [src]="service.icon | safeUrl" alt="" style="height: 95%;">
        </div>
        <a *ngIf="service.type == ServiceType.URL" class="thumb serv-bg" href="{{service.url}}" target="_blank">
          <img *ngIf="service.icon != null" [src]="service.icon | safeUrl" alt="" style="height: 95%;">
        </a>
        <a tabindex="0" *ngIf="service.type == ServiceType.URL" class="custom-link" href="{{service.url}}" target="_blank">
          <h6 class="title">{{service.title}}</h6>
        </a>
        <div tabindex="0" *ngIf="service.type != ServiceType.URL" class="hand" (click)="showServiceDetails(service)" (keyup.enter)="showServiceDetails(service)">
          <h6 class="title">{{service.title}}</h6>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- 5 used services-->
<section class="team-area section-gap article" *ngIf="!isHomePage">
  <div class="container">
    <div class="row justify-content-center d-flex align-items-center mt-5" *ngIf="isLoading">
      <div class="col-12 d-flex flex-wrap justify-content-center mb-5 py-5">
        <div class="spinner w-100 text-center">
          <i class="fa fa-spinner fa-spin fa-2x"></i>
        </div>
      </div>
    </div>
    <div class="row justify-content-center d-flex align-items-center">
      <div class="col-lg-12 single-team wow fadeInUp d-flex flex-column align-items-center w-100"
        [attr.data-wow-delay]="serviceGroup.delay" *ngFor="let serviceGroup of serviceGroups;">
          <div class="eserv-head">
          <h4>{{serviceGroup.title}}</h4>
        </div>
        <div class="row justify-content-center d-flex bg-sub-serv2 wow fadeInUp w-100" data-wow-delay="300ms">
          <div class="col-lg-3 col-md-6 single-team d-flex flex-column w-serv wow fadeInUp"
            [attr.data-wow-delay]="service.delay" *ngFor="let service of serviceGroup.services;">
            <div *ngIf="service.type != ServiceType.URL" class="thumb serv-bg" (click)="showServiceDetails(service)" (keyup.enter)="showServiceDetails(service)">
              <img *ngIf="service.icon != null" [src]="service.icon | safeUrl" alt="" style="height: 95%;">
            </div>
            <a *ngIf="service.type == ServiceType.URL" class="thumb serv-bg" href="{{service.url}}" target="_blank">
              <img *ngIf="service.icon != null" [src]="service.icon | safeUrl" alt="{{service.title}}" style="height: 95%;">
            </a>
            <a tabindex="0" *ngIf="service.type == ServiceType.URL" class="custom-link" href="{{service.url}}" target="_blank">
              <h6 class="title">{{service.title}}</h6>
            </a>
            <div tabindex="0" *ngIf="service.type != ServiceType.URL" class="hand" (click)="showServiceDetails(service)" (keyup.enter)="showServiceDetails(service)">
              <h6 class="title">{{service.title}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div #divTrindingServices class="service-used5 article normal-view" *ngIf="trendingServices?.length > 0"
  [style.marginBottom.px]="viewHeight">
  <div class="open-serv" *ngIf="isClosed && trendingServices?.length > 0" (click)="servicesToogle()">
    <h6>{{'TRENDING_SERVICES' | translate}}</h6>
  </div>
  <div class="close-serv" *ngIf="!isClosed && trendingServices?.length > 0" (click)="servicesToogle()">
    <!--<h6>{{'TRENDING_SERVICES' | translate}}</h6>-->
  </div>
  <div class="title-sused">
    <h6>{{'TRENDING_SERVICES' | translate}}</h6>
  </div>
  <div class="bg-service5">

    <div class="row justify-content-center d-flex wow fadeInUp" data-wow-delay="300ms">
      <div class="col-lg-3 col-md-6 single-team d-flex flex-column w-serv wow fadeInUp"
        [attr.data-wow-delay]="service.delay" *ngFor="let service of trendingServices;">
        <div *ngIf="service.type != ServiceType.URL" class="thumb serv-bg" (click)="showServiceDetails(service)" (keyup.enter)="showServiceDetails(service)">
          <img *ngIf="service.icon != null" alt="" style="height: 95%;" [src]="service.icon | safeUrl">
        </div>
        <a tabindex="0" *ngIf="service.type == ServiceType.URL" class="thumb serv-bg" href="{{service.url}}" target="_blank" title="">
          <img *ngIf="service.icon != null" [src]="service.icon | safeUrl" alt="." style="height: 95%;">
        </a>
        <div tabindex="0" (click)="showServiceDetails(service)" (keyup.enter)="showServiceDetails(service)">
          <h6 class="title">{{service.title}}</h6>
        </div>
      </div>
    </div>
  </div>
</div>