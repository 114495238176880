export class ContactUsViewModel {
    Name: string;
    Email: string;
    Mobile: string;
    EmailSubject: string;
    Message: string; 
    ContactUsSubjectID : string;
    URL : string
    }

    export enum ContactUsSubjectAction {
        Email = 0,
        Internal,
        External,
    }
