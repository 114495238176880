import { Injectable } from '@angular/core';
import { LoginTokenModel } from 'src/app/modules/account/model/account.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  AuthorizationUserKey = 'AuthorizationUser';
  LogoutUserKey = 'LogoutUser';
  isRememberMeKey = 'RememberMeClient';

  private _loggedInUser: LoginTokenModel;

  get loggedInUser(): LoginTokenModel {
    if (this._loggedInUser == null)
      this._loggedInUser = this.getAuthorizationTokenObject()
    return this._loggedInUser;
  }

  get loggedInUserFullName(): string {
    if (this.loggedInUser != null)
      return this.loggedInUser.name;
    return null;
  }

  constructor(private router: Router) { }

  public isAuthenticated(): boolean {
    if (this.loggedInUser == null)
      return false;
    return true;
  }

  public setAuthorizationToken(loginToken: LoginTokenModel, rememberMe: boolean) {
    this._loggedInUser = loginToken;
    localStorage.removeItem(this.LogoutUserKey);
    if (rememberMe) {
      localStorage.setItem(this.isRememberMeKey, "True");
      localStorage.setItem(this.AuthorizationUserKey, JSON.stringify(loginToken));
    }
    else {
      sessionStorage.setItem(this.AuthorizationUserKey, JSON.stringify(loginToken));
    }
  }

  public setAuthorizationTokenAfterRefresh(loginToken: LoginTokenModel) {
    var rememberMe = false;
    var RememberMeKeyValue = localStorage.getItem(this.isRememberMeKey);
    if (RememberMeKeyValue == "True")
      rememberMe = true;

    localStorage.removeItem(this.isRememberMeKey);
    localStorage.removeItem(this.AuthorizationUserKey);
    sessionStorage.removeItem(this.AuthorizationUserKey);

    this.setAuthorizationToken(loginToken, rememberMe);
  }

  public logout() {
    this._loggedInUser = null;

    localStorage.removeItem(this.AuthorizationUserKey);
    sessionStorage.removeItem(this.AuthorizationUserKey);
    localStorage.setItem(this.LogoutUserKey, "LoggedOut");

    this.router.navigate(['account/login']);
  }

  public getAuthorizationTokenObject(): LoginTokenModel {
    try {

      if (localStorage.length > 0) {
        var isLoggedOut = localStorage[this.LogoutUserKey];
        if (isLoggedOut == "LoggedOut")
          return null;

        var objLocal = localStorage[this.AuthorizationUserKey];
        if (objLocal != null)
          return JSON.parse(objLocal) as LoginTokenModel;
      }
      if (sessionStorage.length > 0) {
        var objSession = sessionStorage[this.AuthorizationUserKey];
        if (objSession != null)
          return JSON.parse(objSession) as LoginTokenModel;
      }
      return null;
    }
    catch
    {
      return null;
    }
  }
}
