import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ContactUsService } from 'src/app/modules/contactus/services/services/contactus.service';
import { LoadingService } from 'src/app/common/services/loading.service';
import { ContactUsSubjectAction, ContactUsViewModel } from 'src/app/modules/contactus/model/contactus.model';
import { ContactUsSubjectViewModel } from 'src/app/modules/contactus/model/contactussubject.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  contactUsForm: FormGroup;
  submitted = false;

  private isViewInit: boolean = false;
  isLoading: boolean = false;
  errorOccured: boolean = false;
  isSuccess: boolean = false;
  contactusSubjectCategories : ContactUsSubjectViewModel[];
  hasNoRecords : boolean;

  constructor(
    private contactUsService: ContactUsService,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }


  get f() {
    return this.contactUsForm.controls;
  }

  ngOnInit() {

    this.contactUsForm = this.formBuilder.group({
      name: ["", Validators.required],
      mobile: [
        "", 
        [
          Validators.required,
          Validators.maxLength(15),
          Validators.pattern("[0-9]+"),
        ]
      ],
      subject: [""],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}"),
          
        ],
      ],
      message: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(500),
        ],
      ],
      subjectCategory:[null,Validators.required],
      emailSubject: ["", Validators.required]
    });

    this.getContactUsSubjects();
    
  }

  onSelectedCategoryChange(){

    var action = this.contactUsForm.value.subjectCategory.action;
    var actionValue = this.contactUsForm.value.subjectCategory.url;

    if(action == ContactUsSubjectAction.Internal){
      this.router.navigate([actionValue]);
      this.submitted = false;
      this.contactUsForm.reset();
    }else if(action == ContactUsSubjectAction.External){
        window.open(actionValue);
        this.submitted = false;
        this.contactUsForm.reset();
    }
  }

  sendMail() {   
    this.isLoading = true;
    this.submitted = true;
    this.errorOccured = false;
    this.isSuccess = false;

    this.contactUsForm.updateValueAndValidity();

    // stop here if form is invalid
    if (this.contactUsForm.invalid) {
      this.isLoading = false;
      return;
    }

    if(this.contactUsForm.value.subjectCategory.action != ContactUsSubjectAction.Email ){
      this.isLoading = false;
      return;
    }

    this.loadingService.showLoader();
    var contactObj: ContactUsViewModel = new ContactUsViewModel();
    contactObj.Name = this.contactUsForm.value.name;
    contactObj.Mobile = this.contactUsForm.value.mobile;
    contactObj.Email = this.contactUsForm.value.email;
    contactObj.EmailSubject = this.contactUsForm.value.emailSubject;
    contactObj.Message = this.getMessageContent(
      this.contactUsForm.value.message
    );
    contactObj.ContactUsSubjectID = this.contactUsForm.value.subjectCategory.contactUsSubjectID;

    this.contactUsService
      .sendMail(contactObj)
      .toPromise()
      .then((res) => {
        this.isSuccess = true;
        this.submitted = false;
        this.contactUsForm.reset();
        this.loadingService.hideLoader();
        this.isLoading = false;
      })
      .catch((err) => {
        this.errorOccured = true;
        this.loadingService.hideLoader();
        this.isLoading = false;
      });
  }

  getContactUsSubjects(){
    this.loadingService.showLoader();
    this.contactUsService
    .getContactUsSubjects()
    .toPromise()
    .then((res) => {
      this.contactusSubjectCategories = res;
      this.hasNoRecords = this.contactusSubjectCategories.length <= 0;
      this.loadingService.hideLoader();
    })
    .catch((err) => {
      this.errorOccured = true;
      this.loadingService.hideLoader();
      this.isLoading = false;
    });
  }

  private getMessageContent(str: string): string {
    const element = document.createElement("div");

    try {
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
      element.innerHTML = str;
      str = element.textContent;
      return str;
    } catch (err) {
      return "";
    }
  }

}
