import { Component, OnInit, HostListener, AfterViewInit, OnDestroy, ChangeDetectionStrategy, ElementRef, ViewChild, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { AuthService } from 'src/app/common/services/auth.service';
import { HijriDateService } from 'src/app/common/hijri-date/services/hijri-date.service';
import { PrayerTimesServiceViewModel } from 'src/app/common/hijri-date/model/hijri-date.model';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ServicesService } from 'src/app/modules/services/services/services.service';
import { CustomBasedServiceRoutePath } from 'src/app/modules/services/model/services.model';

declare var $: any;

@Component({
  selector: "app-anonymous-header",
  templateUrl: "./anonymous-header.component.html",
  styleUrls: ["./anonymous-header.component.css"]
})
export class AnonymousHeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  CustomBasedServiceRoutePath = CustomBasedServiceRoutePath;

  isSticky: boolean = false;
  isSearchOpen: boolean = false;
  currentLanguage: string = "ar";

  showCountDownPrayerTime: boolean;
  flipCurrentPrayerTimerIntervalHandle: any;
  timerIntervalHandle: any;

  isMenuOpened: boolean = false;
  isMenuPrayTimeOpened: boolean = false;
  isOpenMenuOne: boolean = false;
  isOpenMenuTwo: boolean = false;

  get userRequestsCount(): number {
    return this.servicesService.userRequestsCount;
  };

  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  get loggedInUserFullName(): string {
    return this.authService.loggedInUserFullName;
  }

  get prayerTimes(): PrayerTimesServiceViewModel {
    return this.hijriDateService.prayerTimes;
  }

  set prayerTimes(val) {
    this.hijriDateService.prayerTimes = val;
  }

  get selectedGregorianDate(): Date {
    return this.hijriDateService.selectedGregorianDate;
  }

  set selectedGregorianDate(val) {
    this.hijriDateService.selectedGregorianDate = val;
    this.getPrayerTimes();
  }

  get remainingTime(): string {
    if (this.prayerTimes != null && this.prayerTimes.remainingTime != null) {
      return (this.prayerTimes.remainingTime.hours < 10 ? '0' + this.prayerTimes.remainingTime.hours.toString() :
        this.prayerTimes.remainingTime.hours.toString()) + ':' +
        (this.prayerTimes.remainingTime.minutes < 10 ? '0' + this.prayerTimes.remainingTime.minutes.toString() :
          this.prayerTimes.remainingTime.minutes.toString()) + ':' +
        (this.prayerTimes.remainingTime.seconds < 10 ? '0' + this.prayerTimes.remainingTime.seconds.toString() :
          this.prayerTimes.remainingTime.seconds.toString());
    }
    return null;
  }

  constructor(public router: Router,
    private localeService: BsLocaleService,
    private hijriDateService: HijriDateService,
    private translate: TranslateService,
    private authService: AuthService,
    private servicesService: ServicesService,
    private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    $("html").attr("lang", this.currentLanguage);
    if (this.currentLanguage == "ar") {
      $("html").attr("dir", "rtl");
    } else {
      $("html").attr("dir", "ltr");
    }
  }

  ngOnDestroy(): void {
    if (this.flipCurrentPrayerTimerIntervalHandle != null) {
      clearInterval(this.flipCurrentPrayerTimerIntervalHandle);
    }
    if (this.timerIntervalHandle != null) {
      clearInterval(this.timerIntervalHandle);
    }
  }

  ngOnInit() {
    this.currentLanguage = this.translate.currentLang;
    this.localeService.use(this.translate.currentLang);
    if (this.isAuthenticated) {
      this.servicesService.getUserRequestsCount();
    }
    //this.getPrayerTimes();
  }

  @HostListener("window:scroll", ["$event"]) // for window scroll events
  onScroll(event) {
    this.isSticky = window.pageYOffset >= 100;
  }

  searchToggle() {
    this.isSearchOpen = !this.isSearchOpen;
  }

  search(key) {
    this.router.navigate(["/search/" + key]);
  }

  openInnerPage(key: any) {
    this.router.navigate(["/inner/" + key]);
  }

  changeLanguage(lang) {
    this.translate.use(lang);
    localStorage.setItem(environment.selectedLanguageKey, lang);
    window.location.assign("/");
  }

  logout() {
    this.authService.logout();
  }

  showNextDay() {
    this.hijriDateService.selectedGregorianDate.setDate(this.selectedGregorianDate.getDate() + 1);
    this.getPrayerTimes();
  }

  showPrevDay() {
    this.hijriDateService.selectedGregorianDate.setDate(this.selectedGregorianDate.getDate() - 1);
    this.getPrayerTimes();
  }

  startRemainingTimeInterval() {
    this.timerIntervalHandle = setInterval(() => {
      let dt = new Date(2000, 6, 1, this.prayerTimes.remainingTime.hours, this.prayerTimes.remainingTime.minutes, this.prayerTimes.remainingTime.seconds, 0);
      dt = new Date(dt.getTime() - 1000);
      this.prayerTimes.remainingTime.hours = dt.getHours();
      this.prayerTimes.remainingTime.minutes = dt.getMinutes();
      this.prayerTimes.remainingTime.seconds = dt.getSeconds();
      if (this.prayerTimes.remainingTime.hours == 0 &&
        this.prayerTimes.remainingTime.minutes == 0 &&
        this.prayerTimes.remainingTime.seconds == 0) {
        clearInterval(this.timerIntervalHandle);
        setTimeout(() => {
          this.getPrayerTimes();
        }, 10000);
      }
    }, 1000);
  }

  getPrayerTimes() {
    this.hijriDateService.getPrayerTimes().subscribe(res => {
      this.prayerTimes = res;

      if (this.flipCurrentPrayerTimerIntervalHandle != null) {
        clearInterval(this.flipCurrentPrayerTimerIntervalHandle);
      }
      if (this.timerIntervalHandle != null) {
        clearInterval(this.timerIntervalHandle);
      }

      if (this.prayerTimes.remainingTime != null) {
        this.startRemainingTimeInterval();

        this.flipCurrentPrayerTimerIntervalHandle = setInterval(() => {
          this.showCountDownPrayerTime = !this.showCountDownPrayerTime;
        }, 3000);
      }
      if (this.hijriDateService.selectedGregorianDate == null) {
        if (res.gregorianDate != null) {
          this.hijriDateService.selectedGregorianDate = new Date(parseInt(res.gregorianDate.year), parseInt(res.gregorianDate.month) - 1, parseInt(res.gregorianDate.day));
        }
      }
    })
  }

  showUserRequests() {
    this.servicesService.showServiceAfterLogin = false;
    this.servicesService.showServiceRequestsAfterLogin = false;
    if (this.isAuthenticated) {
      this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/requests"]);
    } else {
      this.servicesService.showServiceRequestsAfterLogin = true;
      this.router.navigate(["/account/login"]);
    }
  }

  showMosqueSearch() {
    this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/mosquessearch"]);
  }

  showRuqyaSharia() {
    this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/ruqyasharia"]);
  }

  toggleMenu() {
    console.log("Action Done");
    this.isMenuOpened = !this.isMenuOpened;
    if (this.isMenuOpened)
      this.isMenuPrayTimeOpened = false;
  }

  togglePrayTime() {
    this.isMenuPrayTimeOpened = !this.isMenuPrayTimeOpened;
    if (this.isMenuPrayTimeOpened)
      this.isMenuOpened = false;
  }

  toggleMenuOne() {
    if (window.innerWidth < 1500) {
      this.isOpenMenuOne = !this.isOpenMenuOne;
      if (this.isOpenMenuOne)
        this.isOpenMenuTwo = false;
    }
    else
      this.isOpenMenuOne = false;
  }

  toggleMenuTwo() {
    if (window.innerWidth < 1500) {
      this.isOpenMenuTwo = !this.isOpenMenuTwo;
      if (this.isOpenMenuTwo)
        this.isOpenMenuOne = false;
    }
    else
      this.isOpenMenuTwo = false;
  }

  private swipeCoord?: [number, number];
  private swipeTime?: number;

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1000 //
        && Math.abs(direction[0]) > 30 // Long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';

        if (this.currentLanguage == "ar") {
          if (swipe == "next") {
            this.isMenuPrayTimeOpened = false;
          }
          else {
            this.isMenuOpened = false;
          }
        }
        else {
          if (swipe == "next") {
            this.isMenuOpened = false;
          }
          else {
            this.isMenuPrayTimeOpened = false;
          }
        }
        // Do whatever you want with swipe
        // this.isMenuPrayTimeOpened = false;
        // this.isMenuOpened = false;
      }
    }
  }
}
