import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { AppRoutes } from "./app-routing";
import { AnonymousComponent } from "./layouts/anonymous/anonymous/anonymous.component";
import { AuthorizedComponent } from "./layouts/authorized/authorized/authorized.component";
import { HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AnonymousInnerComponent } from "./layouts/anonymous/anonymous-inner/anonymous-inner.component";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { SharedModule } from "./shared/shared.module";
import { AnonymousHeaderComponent } from "./layouts/shared/anonymous-header/anonymous-header.component";
import { FooterComponent } from "./layouts/shared/footer/footer.component";
import { RequestInterceptor } from "./common/services/request.interceptor";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { arLocale } from 'ngx-bootstrap/locale';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfigurationService } from './common/services/config.service';

defineLocale('ar', arLocale);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    AnonymousComponent,
    AuthorizedComponent,
    AnonymousInnerComponent,
    AnonymousHeaderComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    RouterModule.forRoot(AppRoutes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigurationService) => () => configService.load(),
      deps: [ConfigurationService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
