<header class="header-area">

  <!-- Top Header Area -->
  <div class="top-header-area article">
    <div class="h-100 d-md-flex justify-content-between align-items-center">
      <div class="phone-number d-flex">

        <div class="number ChLang" >
          <a (click)="changeLanguage('en')" *ngIf="currentLanguage == 'ar'" title="Change Language to English" > EN </a>
          <a (click)="changeLanguage('ar')" *ngIf="currentLanguage == 'en'" title="تغيير اللغة للغة العربية"  >عربي</a>
        </div>
        <!-- <div class="RegUser">
          <i class="fas fa-user-tie"></i>
          <a href="account/login"> دخـول</a> / <a href="account/register">تسجيـل</a>
        </div> -->

        <div class="RegUser" *ngIf="!isAuthenticated">
          <i class="fas fa-user-tie"></i>
          <a href="account/login"   title="{{'LOGIN.LOGIN' | translate}}"> {{'LOGIN.LOGIN' | translate}} </a> / <a
            href="account/register" title="{{'REGISTER.REGISTER' | translate}} ">{{'REGISTER.REGISTER' | translate}}</a>
        </div>
        <div class="RegUser" *ngIf="isAuthenticated">
          <i class="fas fa-user-tie"></i> {{loggedInUserFullName}}
          <span>/</span> <a (click)="logout()"> {{'LOGIN.LOGOUT' | translate}} </a>
        </div>
        <!--Start Notification-->
        <div class="not-bg" data-toggle="tooltip" title="{{'HEADER.USER_APPLICATION_REQUESTS' |translate}}"
          *ngIf="isAuthenticated">
          <a (click)="showUserRequests()" class="notification">
            <i class="fas fa-bell" style="font-size: 27px;"></i>
            <span class="badge">{{userRequestsCount}}</span>
          </a>
        </div>
        <!--End Notification-->
        <div class="m-pry" (click)="togglePrayTime()">
          <li class="fas fa-mosque">
        </div>
      </div>
      <!--Start Prayer-->

      <div class="phone-number d-flex HideM">
        <div class="icon">
          <a href="https://www.qatarch.com" target="_blank" data-toggle="tooltip" data-placement="right"
          title="{{'Titel.Qcalendar' | translate}}"><img src="\assets/images/qcalendar.svg" alt="{{'Titel.Qcalendar' | translate}}"></a>
          <!--<div class="fas fa-mosque"></div>-->
        </div>
        <div class="number row w-pry">
          <div class="col-xs-2">
            <a href="#" style="padding-left: 10px" (click)="showPrevDay()" title="{{'Titel.Back' | translate}}">
              <div class="fas fa-chevron-right FlipImage"></div>
            </a>

            <h2 class="PrayerDate"> {{prayerTimes?.hijriDate?.fullFormat}} | {{prayerTimes?.gregorianDate?.day}}
              {{prayerTimes?.gregorianDate?.monthName}} {{prayerTimes?.gregorianDate?.year}}
            </h2>
            <a href="#" style="padding-right: 10px" (click)="showNextDay();" class="m-show2" title="">
              <div class="fas fa-chevron-left FlipImage" title=""><span style="display: none;">.</span></div>
            </a>
          </div>
          <div class="col-xs-10">
            <h1 class="PrayerClock mx-1 wow fadeInUp" data-wow-delay="200ms">
              <span *ngFor="let time of prayerTimes?.times; let firsttime = first; let lasttime = last;">
                <span *ngIf="firsttime" style="color:#fff;"> | </span>
                <span [ngClass]="{'upcoming-prayer': time.isUpComming}"
                  style="color:#fff; font-weight:900">{{time.prayerTimeName}} </span>
                <span class="wow fadeInUp w-time-pr" data-wow-delay="200ms" *ngIf="!showCountDownPrayerTime || !time.isUpComming">{{time.time.timeFormat}}</span>
                <span class="wow fadeInUp w-time-pr d-date-pry" data-wow-delay="200ms" *ngIf="showCountDownPrayerTime && time.isUpComming">({{remainingTime}})</span>
                <span style="color:#fff;" *ngIf="!lasttime"> | </span>
              </span>
            </h1>

            <a href="#" style="padding-right: 10px;" (click)="showNextDay();" class="HideIpad" title="{{'Titel.Next' | translate}}">
              <div class="fas fa-chevron-left FlipImage"></div>
            </a>
          </div>
        </div>
        <div class="icon LeftCal">
          <div class="fas fa-calendar-alt" bsDatepicker [(bsValue)]="selectedGregorianDate"
            [placement]="currentLanguage == 'ar' ? 'bottom left' : 'bottom right'" title="{{'Titel.calendar' | translate}}"></div>
        </div>
      </div>
      <!--End Prayer-->

    </div>
  </div>
  <!-- Main Header Area -->
  <div class="main-header-area" id="stickyHeader" [ngClass]="{'is-sticky': isSticky }">
    <div class="classy-nav-container breakpoint-off dark left brand1">
      <nav class="classy-navbar justify-content-between" id="southNav" [ngClass]="{'is-sticky-content': isSticky }">

        <!-- Logo -->
        <a class="nav-brand LogoM" *ngIf="currentLanguage == 'ar'" routerLink="/" ><img id="imglogo" src="assets/images/logo.svg" class="LogoSize" alt="{{'HEADER.MINISTRY_LOGO' | translate}}"></a>
        <a class="nav-brand LogoM" *ngIf="currentLanguage == 'en'" routerLink="/" ><img id="imglogo" src="assets/images/logo.svg" class="LogoSize" alt="{{'HEADER.MINISTRY_LOGO' | translate}}"></a>

        <!-- Navbar Toggler -->
        <div class="classy-navbar-toggler menu-btn" (click)="toggleMenu()">
          <span class="navbarToggler"
            [ngClass]="{'active': isMenuOpened}"><span></span><span></span><span></span></span>
        </div>

        <!-- Start Menu -->
        <div class="classy-menu  menu-index" [ngClass]="{'menu-on': isMenuOpened}" (touchstart)="swipe($event, 'start')"
          (touchend)="swipe($event, 'end')">

          <!-- close btn -->
          <div class="classycloseIcon" (click)="toggleMenu()">
            <div class="cross-wrap"><span class="top"></span><span class="bottom"></span></div>
          </div>

          <div class="classynav article">
            <ul class="MarginMenu MuSize">
              <li  class="cn-dropdown-item has-down pr12"><a tabindex="0" routerLink="/dashboard">
                  <h2 class="d-contents MuSize">{{'HEADER.HOME' | translate}}</h2>
                </a></li>
                <!-- click(function () {                  $("ul").toggle();                  $(this).children('.fa').toggleClass('fa-chevron-up fa-chevron-down');              }); -->
              <li class="cn-dropdown-item has-down pr12">
                <a tabindex="0" id=Menu1 href="javascript:;" (click)="this.toggleMenuOne()" onfocusin="/*alert(this.id);*/ $(this).children('.fa').toggleClass('fa-chevron-up fa-chevron-down');" onfocus1="document.getElementById('Menu1').addClass();" >
                  <h2 class="d-contents MuSize"><i class="fas fa-chevron-down"></i>
                    {{'HEADER.MINISTRYFILE' | translate}}</h2>
                </a>
                <ul class="dropdown mob_menu" [ngClass]="{'mobile_menu_open': isOpenMenuOne}">
                  <li><a tabindex="0" routerLink="/inner/about-ministry">
                      <h2 class="d-contents MuSize">{{'HEADER.MINISTRYBRIEF' |translate}}</h2>
                    </a></li>
                  <li><a tabindex="0" routerLink="/inner/ministry-vision-and-message">
                      <h5 class="d-contents MuSize">{{'HEADER.MINISTRYVISION' | translate}}</h5>
                    </a></li>
                    <li><a tabindex="0" href="https://islam.gov.qa/pdf/Minister-Cv.pdf" target="_blank">
                      <h5 class="d-contents MuSize">{{'HEADER.MINISTERCV' | translate}}</h5>
                    </a></li>
                  <li><a tabindex="0" routerLink="/inner/ministry-department">
                      <h5 class="d-contents MuSize">{{'HEADER.MINISTRYDEPARTMENTS' | translate}}</h5>
                    </a></li>
                    <li><a tabindex="0" href="/assets/images/awqaf-strategic.pdf" target="_blank">
                      <h5 class="d-contents MuSize">{{'HEADER.MINISTRYSTRATEGIC' | translate}}</h5>
                    </a></li>
                  <li><a tabindex="0" routerLink="/inner/ministry-oc">
                      <h5 class="d-contents MuSize">{{'HEADER.MINISTRYARCHITECTURE' |translate}}</h5>
                    </a></li>
                    <li><a tabindex="0" href="/assets/images/Ministry-guide.pdf" target="_blank">
                      <h5 class="d-contents MuSize">{{'HEADER.MINISTRYGUIDE' |translate}}</h5>
                    </a></li>
                </ul>
              </li>
              <li class="cn-dropdown-item has-down pr12"><a tabindex="0" href="#" (click)="toggleMenuTwo()" (focus)="toggleMenuTwo()">
                  <h2><i class="fas fa-chevron-down"></i> {{'HEADER.SITEGUIDE' |translate}}</h2>
                </a>
                <ul class="dropdown mob_menu" [ngClass]="{'mobile_menu_open': isOpenMenuTwo}">
                  <li><a tabindex="0" href="https://www.diwan.gov.qa" target="_blank">
                    <h5 class="d-contents MuSize">{{'HEADER.Diwan' |translate}}</h5>
                  </a></li>
                  <li><a tabindex="0" routerLink="/inner/related-links">
                      <h5 class="d-contents MuSize">{{'HEADER.RELEATEDSITES' |translate}}</h5>
                    </a></li>
                  <li><a tabindex="0" href="https://www.gov.qa" target="_blank">
                      <h5 class="d-contents MuSize">{{'HEADER.GOVERNMONET' |translate}}</h5>
                    </a></li>
                  <li><a tabindex="0" href="https://mawared.qa" target="_blank">
                      <h5 class="d-contents MuSize">{{'HEADER.RESOURCES' |translate}}</h5>
                    </a></li>
                </ul>
              </li>
              <li class="cn-dropdown-item has-down pr12"><a tabindex="0" href="#" (click)="toggleMenuTwo()" (focus)="toggleMenuTwo()">
                <h2><i class="fas fa-chevron-down"></i> {{'HEADER.EMPLOYEEWINDOW' |translate}}</h2>
              </a>
              <ul class="dropdown mob_menu" [ngClass]="{'mobile_menu_open': isOpenMenuTwo}">
                <li><a tabindex="0" href="https://outlook.office365.com" target="_blank">
                  <h5 class="d-contents MuSize">{{'HEADER.Email' |translate}}</h5>
                </a></li>
                <li><a tabindex="0" href="https://islam.gov.qa/pdf/القيم الوظيفية.pdf" target="_blank">
                    <h5 class="d-contents MuSize">{{'HEADER.Qaem' |translate}}</h5>
                  </a></li>
                
              </ul>
            </li>
              <!--<li class="cn-dropdown-item has-down pr12"><a tabindex="0" href="https://email.islam.gov.qa" target="_blank">
                  <h2>{{'HEADER.EMPLOYEEWINDOW' | translate}}</h2>
                </a></li>-->
              <li class="cn-dropdown-item has-down pr12"><a tabindex="0" routerLink="/contactus">
                  <h2>{{'HEADER.CONTACT_US' |translate}}</h2>
                </a></li>
              <li class="cn-dropdown-item has-down pr12"><a tabindex="0" routerLink="/{{CustomBasedServiceRoutePath.GroupsAndServices}}">
                  <h2>{{'HEADER.SERVICES' |translate}}</h2>
                </a></li>
              <!-- <li><a (click)="showUserRequests()">
                  <h5>{{'HEADER.USER_APPLICATION_REQUESTS' |translate}}</h5>
                </a></li> -->
              <!-- <li><a (click)="showRuqyaSharia()">
                  <h5>{{'HEADER.RUQYA_SHARIA'| translate}}</h5>
                </a></li> -->
              <!-- <li><a (click)="showMosqueSearch()">
                  <h5>{{'SEARCHMOSQUE.SEARCH_HEADING'| translate}}</h5>
                </a></li> -->

<li class="m-disp">
              <ul class="SocialMedia">
                <li><a href="https://www.facebook.com/AWQAFM" target="_blank" title="{{'Titel.Facebook' | translate}}"><i class="fab fa-facebook-f SMSize"></i></a></li>
                <li><a href="https://twitter.com/AWQAFM" target="_blank" title="{{'Titel.Twitter' | translate}}"><i class="fa-brands fa-x-twitter SMSize"></i></a></li>
                <li><a href="https://www.youtube.com/user/awqafministryqatar" target="_blank" title="{{'Titel.Youtube' | translate}}"><i class="fab fa-youtube SMSize"></i></a></li>
                <li><a href="https://www.instagram.com/awqafm/" target="_blank" title="{{'Titel.Instagram' | translate}}"><i class="fab fa-instagram SMSize"></i></a></li>
                <!--<a href="#" class="HidePc">
                  <h5>English</h5>
                </a>
                 <div class="RegUser HidePc">
                  <i class="fas fa-user-tie"></i>
                  <a href="account/login"> دخـول </a> / <a href="account/register">تسجيـل</a>
                </div>

                <div class="RegUser HidePc" *ngIf="!isAuthenticated">
                  <i class="fas fa-user-tie"></i>
                  <a href="account/login"> {{'LOGIN.LOGIN' | translate}} </a> / <a
                    href="account/register">{{'REGISTER.REGISTER' | translate}}</a>
                </div>
                <div class="RegUser HidePc" *ngIf="isAuthenticated">
                  <i class="fas fa-user-tie"></i> {{loggedInUserFullName}}
                  <a (click)="logout()"> {{'LOGIN.LOGOUT' | translate}} </a>
                </div> -->

              </ul>
            </li>
            </ul>
            <!-- Search Form -->
            <div class="SearchIcon" onclick="javascript:;" >
              <div class="south-search-form search-control" [ngClass]="{'show-search': isSearchOpen }">              
                <label for="search" style="display: none;" >{{'HEADER.SEARCHHERE' | translate}}</label>
                <input type="search" #key (keyup.enter)="search(key.value)" id="search"
                  placeholder="{{'HEADER.SEARCHHERE' | translate}} ..">
                <button type="button" aria-label="Search Icon"><i class="fa fa-search" aria-hidden="true"  href="#"
                    (click)="search(key.value)"><span style="display: none;">.</span></i></button>
              </div>
              <!-- Search Button -->
              <a (click)="searchToggle()" href="#" class="searchbtn" onclick="javascript:;" title="{{'HEADER.SEARCHHERE' | translate}}"><i class="fa" aria-hidden="true"></i></a>              
            </div>
          </div>
          <div class="MobCont">
            <div class="SocialMob"></div>
            <div class="AppMob">
              <i class="fab fa-apple"></i>
              <i class="fab fa-android"></i>
            </div>
          </div>
        </div>
        <!--End Menu -->

        <!-- Start Menu Left -->
        <div class="classy-menu  menu-index praytime-mobile article"
          [ngClass]="{'menu-praytime-on': isMenuPrayTimeOpened}" (touchstart)="swipe($event, 'start')"
          (touchend)="swipe($event, 'end')">

          <!-- close btn -->
          <div class="close-style1 M-show">
            <div class="classycloseIcon close-pry" (click)="togglePrayTime()">
              <div class="cross-wrap"><span class="top"></span><span class="bottom"></span></div>
            </div>
            <div class="txt-style2">
              <h6>{{'PRAYER_TIMES' | translate}}</h6>
            </div>
          </div>

          <div class="classynav mrg-prym M-show">
            <div style="display: flex;" title="{{'Titel.Qcalendar' | translate}}">
              <img src="\assets/images/qcalendar.svg" width="60px" alt="{{'Titel.Qcalendar' | translate}}">
              <div class="txt-style1">
                <h6>{{'QATAR_PRAYER_TIMES' | translate}}</h6>
              </div>
            </div>

            <div class="round-pry">
              <div style="display: flex;">
                <div class="fas fa-chevron-right FlipImage" style="margin: 5px;" (click)="showPrevDay()"></div>
                <div class="date-calm">
                  <h5>{{prayerTimes?.hijriDate?.fullFormat}}<br>{{prayerTimes?.gregorianDate?.day}}
                    {{prayerTimes?.gregorianDate?.monthName}} {{prayerTimes?.gregorianDate?.year}}</h5>
                </div>
                <div class="fas fa-chevron-left FlipImage" style="margin: 5px;" (click)="showNextDay();"></div>
                <div class="calender-m">
                  <li class="fas fa-calendar-alt" bsDatepicker [(bsValue)]="selectedGregorianDate"
                    [placement]="currentLanguage == 'ar' ? 'bottom left' : 'bottom right'">
                </div>
              </div>
              <div style="padding: 10px;">
                <div class="time-prym" *ngFor="let time of prayerTimes?.times; let index = index">
                  <div class="icon-prym" *ngIf="index==0"><img src="\assets/images/fajer.svg" width="40px" title="" alt=""></div>
                  <div class="icon-prym" *ngIf="index==1"><img src="\assets/images/shroq.svg" width="40px" title="" alt=""></div>
                  <div class="icon-prym" *ngIf="index==2"><img src="\assets/images/dhuhr.svg" width="40px" title="" alt=""></div>
                  <div class="icon-prym" *ngIf="index==3"><img src="\assets/images/asr.svg" width="40px" title="" alt=""></div>
                  <div class="icon-prym" *ngIf="index==4"><img src="\assets/images/maghrab.svg" width="40px" title="" alt=""></div>
                  <div class="icon-prym" *ngIf="index==5"><img src="\assets/images/isha.svg" width="40px" title="" alt=""></div>
                  <h6 class="align-prym1" [ngClass]="{'upcoming-prayer-mobile': time.isUpComming}">
                    {{time.prayerTimeName}}</h6>
                  <h6 class="align-prym2" *ngIf="!showCountDownPrayerTime || !time.isUpComming">{{time.time.timeFormat}}
                  </h6>
                  <h6 class="align-prym2" *ngIf="showCountDownPrayerTime && time.isUpComming">({{remainingTime}})</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--End Menu Left -->

      </nav>
    </div>
  </div>
</header>
