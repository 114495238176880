export enum ServiceLanguageType { Arabic = "Arabic", English = "English" }
export enum ServiceStatus { InActive = "InActive", Active = "Active" }
export enum ServiceType { WorkFlow = "WorkFlow", Custom = "Custom", URL = "URL", SMS = "SMS" }
export enum CustomBasedServiceType {
    SearchMosques = "SearchMosques",
    RuqyaSharia = "RuqyaSharia",
    DawaSMSSubscription = "DawaSMSSubscription",
    ReligiousInquiryViaPhone = "ReligiousInquiryViaPhone",
    ReligiousInquiryViaSMS = "ReligiousInquiryViaSMS",
    LecturesEvents = "LecturesEvents",
    CourseEvents = "CourseEvents",
    LessonEvents = "LessonEvents",
    JassimCompetitionCheckTestResults = "JassimCompetitionCheckTestResults",
    GenerateQuranTestModel = "GenerateQuranTestModel",
    SchoolCompetitionCheckTestResults = "SchoolCompetitionCheckTestResults",
    SchoolCompetitionChangeStudentPhotoRequest = "SchoolCompetitionChangeStudentPhotoRequest",
    ImamExaminationResidentCheckTestResults = "ImamExaminationResidentCheckTestResults",
    ImamExaminationNonResidentCheckTestResults = "ImamExaminationNonResidentCheckTestResults",
    Publications = "Publications",
    QuranCenterPrintCertificateRequest = "QuranCenterPrintCertificateRequest",
    QuranCenterStudentRegistration="QuranCenterNewStudentRegistration",
    ChangeQuranCenter="QuranCenterCenterChangeRequest"
}

export enum CustomBasedServiceRoutePath {
    GroupsAndServices = "e-services",
    SearchMosques = "search-mosques",
    RuqyaSharia = "ruqya-sharia",
    DawaSMSSubscription = "dawa-sms-subscription",
    ReligiousInquiryViaPhone = "religious-inquiry-via-phone",
    ReligiousInquiryViaSMS = "religious-inquiry-via-sms",
    LecturesEvents = "lectures",
    CourseEvents = "courses",
    LessonEvents = "lessons",
    JassimCompetitionCheckTestResults = "jassim-competition-check-test-results",
    GenerateQuranTestModel = "jassim-competition-generate-test-model",
    SchoolCompetitionCheckTestResults = "school-competition-check-test-results",
    SchoolCompetitionChangeStudentPhotoRequest = "school-competition-change-photo",
    ImamExaminationResidentCheckTestResults = "imam-examination-resident-check-test-results",
    ImamExaminationNonResidentCheckTestResults = "imam-examination-non-resident-check-test-results",
    Publications = "publications",
    QuranCenterPrintCertificateRequest = "print-student-certificate",
    QuranCenterStudentRegistration="student-registration",
    ChangeQuranCenter="app-update-quran-center"
}

export class ServiceViewModel {
    serviceId: string;
    title: string;
    description: string;
    languageType: ServiceLanguageType;
    languageTypeName: string;
    status: ServiceStatus;
    statusName: string;
    type: ServiceType;
    typeName: string;
    groupId: string;
    groupName?: string;
    iconId: string;
    icon?: any;
    delay?: string;
    order: number;
    trackingSystemNumber: number;
    workFlowServiceNumber: number;
    customBasedServiceType: CustomBasedServiceType;
    customBasedServiceTypeName: string;
    url: string;
    serviceParameters: ServiceParameter[];
}

export class ServiceParameter {
    parameterName: string;
    parameterValue: string;
}

export class GroupForPublicViewModel {
    groupId: string;
    title: string;
    description: string;
    order: number;
    iconId: string;
    icon?: any;
    delay?: string;
    services: ServiceViewModel[];
}

export enum ServiceFieldType {
    Text = "Text",
    Number = "Number",
    Date = "Date",
    Logic = "Logic",
    Menu = "Menu",
    TextArea = "TextArea",
    Email = "Email",
    Location = "Location",
    PredefinedList = "PredefinedList",
    Time = "Time"
}

export class WorkFlowServiceDropdownListData {
    dropListId: number;
    serviceDataId: number;
    dropListKey: number;
    dropListValue: string;
}

export enum DateCustomValidationType { PastDate = "PastDate", FutureDate = "FutureDate", DateRange = "DateRange" }

export class DateCustomValidationData {
    serviceDataId: number;
    validationType: DateCustomValidationType;
    numberOfDays: number;
    dateFrom: Date;
    dateTo: Date;
    dateFromFormatted: string;
    dateToFormatted: string;
}

export class NumberCustomValidationData {
    serviceDataId: number;
    minimumValue: number;
    maximumValue: number;
}

export class WorkFlowServicePredefinedDropdownListData {
    dropDownListItemId: number;
    name: string;
    dropDownListId: number;
    parentDropDownListItemId: number;
}

export class WorkFlowServiceFieldData {
    serviceDataId: number;
    serviceId: number;
    serviceName: string;
    fieldName: string;
    mandatory: boolean;
    fieldTypeName: string;
    fieldTypeId: ServiceFieldType;
    effectiveField: number;
    dropDownListId: number;
    hasCustomValidation: boolean;
    dropdownListData: WorkFlowServiceDropdownListData[];
    preDefinedDropdownListData: WorkFlowServicePredefinedDropdownListData[];
    dateCustomValidationData: DateCustomValidationData;
    numberCustomValidationData: NumberCustomValidationData;
    isFieldValid?: boolean;
}

export class WorkFlowServiceDocumentData {
    serviceDocumentId: number;
    documentName: string;
    mandatory: boolean;
    documentId: number;
    documentUrl: string;
    description: string;
    isDocumentValid?: boolean;
    errorMessage?: string;
    fileName?: any;
}

export class WorkFlowServiceDetailData {
    fields: WorkFlowServiceFieldData[];
    documents: WorkFlowServiceDocumentData[];
}

export class MediaBindingModel {
    fileActualName: string
    uploadedFileName: string;
}

export class WorkFlowServiceApplicationFieldBindingModel {
    serviceDataId: number;
    fieldTypeId: string;
    serviceDataValue: string;
}

export class WorkFlowServiceApplicationDocumentBindingModel {
    serviceDocumentId: number;
    tempFileName: string;
}

export class WorkFlowServiceApplicationRequestBindingModel {
    fields: WorkFlowServiceApplicationFieldBindingModel[]
    documents: WorkFlowServiceApplicationDocumentBindingModel[];
}

export class WorkFlowServiceApplicationResultData {
    isSuccess: boolean;
    applicationId: number;
    resultMessage: string;
}

export enum WorkFlowServiceApplicationStatus { InProgress = "InProgress", Approved = "Approved", Rejected = "Rejected" }

export class WorkFlowServiceApplicationViewModel {
    applicationId: number;
    applicationDateFormated: Date;
    status: WorkFlowServiceApplicationStatus;
    notes: string;
    serviceName: string;
}

export class WorkFlowServiceApplicationViewModelPaginationResult {
    totalResultCount: number;
    result: WorkFlowServiceApplicationViewModel[];
}

export enum SortDirection { Ascending = "Ascending", Descending = "Descending" }
export enum WorkFlowServiceApplicationSortField { CreationDateTime = "CreationDateTime" }

export class ApplicationSearchBindingModel {
    constructor() {
        this.direction = SortDirection.Descending;
        this.sorting = WorkFlowServiceApplicationSortField.CreationDateTime;
        this.pageIndex = 0;
        this.pageSize = 1;
    }
    direction: SortDirection;
    sorting: WorkFlowServiceApplicationSortField;
    pageIndex: number;
    pageSize: number;
    applicationId: number;
    serviceId: string;
    applicationDate: Date;
    status: WorkFlowServiceApplicationStatus;
}