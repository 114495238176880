import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ValidateClientUserBindingModel, RegisterClientUserBindingModel, LoginTokenModel, RequestLoginOneTimePasswordBindingModel, LoginClientUserBindingModel } from '../model/account.model';
import { ConfigurationService } from "src/app/common/services/config.service";

@Injectable({
  providedIn: "root",
})
export class AccountService {

  constructor(private http: HttpClient,private configService: ConfigurationService) { }

  validate(model: ValidateClientUserBindingModel) {
    return this.http.post(this.configService.ServiceGatewayBrokerAPIURL + 'Account/Register/OneTimePassword', model);
  }

  register(model: RegisterClientUserBindingModel) {
    return this.http.post<LoginTokenModel>(this.configService.ServiceGatewayBrokerAPIURL + 'Account/Register', model);
  }

  authenticate(model: RequestLoginOneTimePasswordBindingModel) {
    return this.http.post(this.configService.ServiceGatewayBrokerAPIURL + 'Account/Authenticate/OneTimePassword', model);
  }

  token(model: LoginClientUserBindingModel) {
    return this.http.post<LoginTokenModel>(this.configService.ServiceGatewayBrokerAPIURL + 'Account/Token', model);
  }

  RefreshToken(refresh_token: string) {
    var model = new FormData();
    model.append("Refresh_Token", refresh_token);
    return this.http.post<LoginTokenModel>(this.configService.ServiceGatewayBrokerAPIURL + 'Account/RefreshToken', model);
  }
}
