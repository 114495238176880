import { FileCategory } from './../../modules/services/model/publication.model';
import { PipeTransform, Pipe } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from '../services/config.service';

@Pipe({ name: 'ngxShowImage' })
export class ShowImagePipe implements PipeTransform {

    constructor(private configService: ConfigurationService) { }

    transform(input: string): string | SafeUrl {
        if (input == null || input.startsWith('data:'))
            return input;

        return this.configService.ServiceURL + 'media/' + input;
    }

}
