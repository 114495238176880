import { Injectable, EventEmitter } from "@angular/core";


@Injectable()
export class LoadingService {

    showLoaderEvent: EventEmitter<boolean> = new EventEmitter();
    hideLoaderEvent: EventEmitter<boolean> = new EventEmitter();

    showLoader() {
        this.showLoaderEvent.emit();
    };

    hideLoader() {
        this.hideLoaderEvent.emit();
    };
}