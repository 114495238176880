import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";

import { Observable, throwError, BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from './auth.service';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { AccountService } from 'src/app/modules/account/services/account.service';
import { ConfigurationService } from "./config.service";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private authService: AuthService,
    private accountService: AccountService,private configService: ConfigurationService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    req = this.setLanguageHeader(req);
    req = this.setServiceGatewayBrokerAuthorizationToken(req);

    return next.handle(req).pipe(catchError(err => {
      if (err.status === 401 || err.status === 403 || err.status === 0) {
        if (req.url.toLowerCase().indexOf("/media/") == -1 && req.url.toLowerCase().indexOf(this.configService.ServiceGatewayBrokerAPIURL.toLowerCase()) > -1) {
          return this.handleServiceGatewayBrokerRefreshToken(next, err, req);
        } else
          return throwError(err);
      } else
        return throwError(err);
    }));
  }

  private setLanguageHeader(req: HttpRequest<any>) {

    let selectedLanguage = localStorage.getItem(
      environment.selectedLanguageKey
    );

    if (!selectedLanguage) selectedLanguage = "ar";

    if (selectedLanguage == "ar") {
      req = req.clone({
        headers: req.headers.set("Accept-Language", "ar-QA"),
      });
    } else {
      req = req.clone({
        headers: req.headers.set("Accept-Language", "en-US"),
      });
    }

    return req;
  }

  private handleServiceGatewayBrokerRefreshToken(next: HttpHandler, err: any, req: HttpRequest<any>) {
    let tokenModel = this.authService.getAuthorizationTokenObject();
    var refreshToken = "";
    if (tokenModel != null && tokenModel.refresh_token != null) {
      refreshToken = tokenModel.refresh_token;
    }
    if (refreshToken == "") {
      this.authService.logout();
      return throwError(err);
    }
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap((response) => {
          return this.handleServiceGatewayBrokerRefreshTokenResponse(next, response, req);
        })
      );

    } else {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);
      return this.accountService.RefreshToken(refreshToken).pipe(

        catchError(err => {
          this.refreshTokenInProgress = false;

          if (err.status === 401 || (err.status === 500 && (err.url && err.url.toLowerCase().indexOf("/media/") == -1)) || (err.url && err.url.toLowerCase().indexOf("refreshtoken") > -1)) {
            this.authService.logout();
            return throwError(err);
          }
        }),
        switchMap((response: any) => {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(response);

          return this.handleServiceGatewayBrokerRefreshTokenResponse(next, response, req);
        }));
    }
  }

  private handleServiceGatewayBrokerRefreshTokenResponse(next: HttpHandler, response: any, req: HttpRequest<any>) {
    this.authService.setAuthorizationTokenAfterRefresh(response);
    let tokenModel = this.authService.getAuthorizationTokenObject();
    req = req.clone({
      headers: req.headers.set('Authorization', tokenModel.token_type + ' ' + tokenModel.access_token)
    });
    return next.handle(req);
  }

  private setServiceGatewayBrokerAuthorizationToken(req: HttpRequest<any>) {
    if (req.url.toLowerCase().indexOf(this.configService.ServiceGatewayBrokerAPIURL.toLowerCase()) > -1) {
      if (req.url.toLowerCase().indexOf('token') > -1 ||
        req.url.toLowerCase().indexOf('account/register') > -1 ||
        req.url.toLowerCase().indexOf('refreshtoken') > -1 ||
        req.url.toLowerCase().indexOf('onetimepassword') > -1) {
        req = req.clone({
          headers: req.headers.set('Authorization', 'Basic ' + btoa(environment.ServiceGatewayBrokerAPIClientId + ':' + environment.ServiceGatewayBrokerAPIClientSecret))
        });

      } else {
        let tokenModel = this.authService.getAuthorizationTokenObject();

        if (tokenModel != null && tokenModel.access_token != null && tokenModel.access_token != '') {

          req = req.clone({
            headers: req.headers.set('Authorization', tokenModel.token_type + ' ' + tokenModel.access_token)
          });

        }

      }
    }
    return req;
  }
}
