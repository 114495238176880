import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

interface Configuration {
    ServiceURL: string;
    websiteURL: string;
    ServiceGatewayBrokerAPIURL: string;
    PublicationsFilesURL: string;
}

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
    private readonly configUrl = 'assets/appsettings.json';
    private config: Configuration;

    constructor(private http: HttpClient) { }

    public load() {
        if (!this.config) {
            this.http.get<Configuration>(`${this.configUrl}`).subscribe(res => this.config = res);
        }
    }

    get ServiceURL(): string {
        if (this.config)
            return this.config.ServiceURL;
        return "";
    }
    get websiteURL(): string {
        if (this.config)
            return this.config.websiteURL;
        return "";
    }
    get ServiceGatewayBrokerAPIURL(): string {
        if (this.config)
            return this.config.ServiceGatewayBrokerAPIURL;
        return "";
    }
    get PublicationsFilesURL(): string {
        if (this.config)
            return this.config.PublicationsFilesURL;
        return "";
    }
}