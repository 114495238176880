import { PipeTransform, Pipe } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { FileCategory } from 'src/app/modules/services/model/publication.model';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from '../services/config.service';

@Pipe({ name: 'ngxReadPublication' })
export class ReadPublicationPipe implements PipeTransform {

    constructor(private configService: ConfigurationService) { }

    transform(input: string): string | SafeUrl {
        if (input == null || input.startsWith('data:'))
            return input;

        return this.configService.ServiceGatewayBrokerAPIURL + 'Publication/Stream/' + input  ;

    }

}
