import { NotFoundComponent } from "./common/error/component/not-found/not-found.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthorizedComponent } from "./layouts/authorized/authorized/authorized.component";
import { AnonymousComponent } from "./layouts/anonymous/anonymous/anonymous.component";
import { CustomBasedServiceRoutePath } from './modules/services/model/services.model';

export const AppRoutes: Routes = [
  {
    path: "",
    component: AnonymousComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        //redirectTo: "dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        //loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        redirectTo: "",
      },
      {
        path: "login",
        loadChildren:
          () => import('./common/authenticate/authenticate.module').then(m => m.AuthenticateModule),
      },
      {
        path: CustomBasedServiceRoutePath.GroupsAndServices,
        loadChildren: () => import('./modules/services/services.module').then(m => m.ServicesModule),
      },
      {
        path: "news",
        loadChildren: () => import('./modules/news/news.module').then(m => m.NewsModule),
      },
      {
        path: "inner",
        loadChildren: () => import('./modules/inner/inner.module').then(m => m.InnerModule),
      },
      {
        path: "contactus",
        loadChildren: () => import('./modules/contactus/contactus.module').then(m => m.ContactUsModule),
      },
      {
        path: "search",
        loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
      },
      {
        path: "account",
        loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
      },
    ],
  },
  {
    path: "",
    component: AuthorizedComponent,
    children: [
      {
        path: "error",
        loadChildren: () => import('./common/error/error.module').then(m => m.ErrorModule),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "error/notfound",
  },
];
