import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  GroupForPublicViewModel, ServiceViewModel,
  ServiceType, CustomBasedServiceType, ApplicationSearchBindingModel,
  WorkFlowServiceApplicationViewModelPaginationResult, CustomBasedServiceRoutePath
} from '../model/services.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ConfigurationService } from 'src/app/common/services/config.service';

@Injectable({
  providedIn: 'root'
})

export class ServicesService {

  public showServiceAfterLogin: boolean;
  public showServiceRequestsAfterLogin: boolean;
  public serviceGroups: GroupForPublicViewModel[];
  public services: ServiceViewModel[];
  public selectedService: ServiceViewModel;
  public selectedServiceGroup: GroupForPublicViewModel;
  public tempSelectedServiceGroup: GroupForPublicViewModel;
  public userRequestsCount: number = 0;
  public isLoadingGroups: boolean = false;
  public showServicesInGroup: boolean;

  constructor(
    private http: HttpClient,
    private router: Router, private configService: ConfigurationService
  ) { }

  getAllGroups(selectedGroupId?: string) {
    this.getShowServicesInGroupSetting(selectedGroupId);
  }

  getBase64Image(mediaId: string) {
    return this.http.get<string>(this.configService.ServiceGatewayBrokerAPIURL + 'Media/base64/' + mediaId);
  }

  getUserRequestsCount() {
    this.userRequestsCount = 0;
    this.searchUserRequests(new ApplicationSearchBindingModel()).subscribe(res => {
      if (res && res.totalResultCount) {
        this.userRequestsCount = res.totalResultCount;
      }
    }, err => {
      this.userRequestsCount = 0;
    })
  }

  searchUserRequests(model: ApplicationSearchBindingModel) {
    let qryString: string = '?';

    qryString = qryString + 'PaginationRequest.PageSize=' + model.pageSize + "&";
    qryString = qryString + 'PaginationRequest.PageIndex=' + model.pageIndex + "&";
    qryString = qryString + 'PaginationRequest.Sorting=' + model.sorting + "&";
    qryString = qryString + 'PaginationRequest.Direction=' + model.direction + "&";

    if (model.applicationId != null && model.applicationId != 0) {
      qryString = qryString + 'ApplicationId=' + model.applicationId + "&";
    }
    if (model.serviceId != null && model.serviceId != '') {
      qryString = qryString + 'ServiceId=' + model.serviceId + "&";
    }
    if (model.applicationDate != null) {
      qryString = qryString + 'ApplicationDate=' + model.applicationDate.toISOString() + "&";
    }
    if (model.status != null) {
      qryString = qryString + 'Status=' + model.status + "&";
    }
    if (qryString != '') {
      qryString = qryString.substring(0, qryString.length - 1);
    }
    return this.http.get<WorkFlowServiceApplicationViewModelPaginationResult>(this.configService.ServiceGatewayBrokerAPIURL + 'Application' + qryString);
  }

  searchServices(searchText: string) {
    if (searchText == null || searchText.trim() == '')
      searchText = '';
    return this.http.get<ServiceViewModel[]>(this.configService.ServiceGatewayBrokerAPIURL + 'Service?searchText=' + searchText);
  }

  searchSectionServices(sectionId: string) {
    if (sectionId == null || sectionId.trim() == '')
    sectionId = '';
    return this.http.get<ServiceViewModel[]>(this.configService.ServiceGatewayBrokerAPIURL + 'Service/SectionService?sectionId=' + sectionId);
  }

  trendingServices() {
    return this.http.get<ServiceViewModel[]>(this.configService.ServiceGatewayBrokerAPIURL + 'Service/Trending');
  }

  redirectAfterLogin() {
    if (this.showServiceAfterLogin && this.selectedService != null) {
      this.showServiceAfterLogin = false;
      if (this.selectedService.type == ServiceType.WorkFlow) {
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/", this.selectedService.serviceId]);
      } else if (this.selectedService.type == ServiceType.Custom) {
        this.redirectToCustomBasedApplication(this.selectedService.customBasedServiceType);
      } else {
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices]);
      }
    } else if (this.showServiceRequestsAfterLogin) {
      this.showServiceRequestsAfterLogin = false;
      this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/requests"]);
    } else {
      this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices]);
    }
  }

  redirectToCustomBasedApplication(type: CustomBasedServiceType){   
   switch (type) {
      case CustomBasedServiceType.SearchMosques:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.SearchMosques]);
        break;

      case CustomBasedServiceType.RuqyaSharia:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.RuqyaSharia]);
        break;

      case CustomBasedServiceType.DawaSMSSubscription:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.DawaSMSSubscription]);
        break;

      case CustomBasedServiceType.ReligiousInquiryViaPhone:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.ReligiousInquiryViaPhone]);
        break;

      case CustomBasedServiceType.ReligiousInquiryViaSMS:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.ReligiousInquiryViaSMS]);
        break;

      case CustomBasedServiceType.LecturesEvents:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.LecturesEvents]);
        break;

      case CustomBasedServiceType.CourseEvents:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.CourseEvents]);
        break;

      case CustomBasedServiceType.LessonEvents:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.LessonEvents]);
        break;

      case CustomBasedServiceType.JassimCompetitionCheckTestResults:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.JassimCompetitionCheckTestResults]);
        break;

      case CustomBasedServiceType.GenerateQuranTestModel:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.GenerateQuranTestModel]);
        break;

      case CustomBasedServiceType.SchoolCompetitionCheckTestResults:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.SchoolCompetitionCheckTestResults]);
        break;

      case CustomBasedServiceType.SchoolCompetitionChangeStudentPhotoRequest:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.SchoolCompetitionChangeStudentPhotoRequest]);
        break;

      case CustomBasedServiceType.ImamExaminationResidentCheckTestResults:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.ImamExaminationResidentCheckTestResults]);
        break;

      case CustomBasedServiceType.ImamExaminationNonResidentCheckTestResults:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.ImamExaminationNonResidentCheckTestResults]);
        break;
  
      case CustomBasedServiceType.Publications:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.Publications + "/" + this.selectedService.serviceParameters[0].parameterValue]);
        break;
      
        case CustomBasedServiceType.QuranCenterPrintCertificateRequest:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.QuranCenterPrintCertificateRequest]);
        break;
        
        case CustomBasedServiceType.QuranCenterStudentRegistration:
          this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.QuranCenterStudentRegistration]);
          break;
  

        case CustomBasedServiceType.ChangeQuranCenter:
          this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/" + CustomBasedServiceRoutePath.ChangeQuranCenter]);
          break;

      default:
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices]);
        break;
    }
  }

  showServiceDetails(isAuthenticated: boolean) {
    if (this.selectedService.type == ServiceType.WorkFlow) {
      if (isAuthenticated) {
        this.router.navigate(["/" + CustomBasedServiceRoutePath.GroupsAndServices + "/", this.selectedService.serviceId]);
      } else {
        this.showServiceAfterLogin = true;
        this.router.navigate(["/account/login"]);
      }
    } else if (this.selectedService.type == ServiceType.Custom) {
      if (this.selectedService.customBasedServiceType == CustomBasedServiceType.DawaSMSSubscription ||
        this.selectedService.customBasedServiceType == CustomBasedServiceType.SchoolCompetitionCheckTestResults ||
        this.selectedService.customBasedServiceType == CustomBasedServiceType.SchoolCompetitionChangeStudentPhotoRequest ||
        this.selectedService.customBasedServiceType == CustomBasedServiceType.ImamExaminationResidentCheckTestResults
      ) {
        if (isAuthenticated) {
          this.redirectToCustomBasedApplication(this.selectedService.customBasedServiceType);
        } else {
          this.showServiceAfterLogin = true;
          this.router.navigate(["/account/login"]);
        }
      } else {
        this.redirectToCustomBasedApplication(this.selectedService.customBasedServiceType);
      }
    } else if (this.selectedService.type == ServiceType.URL && this.selectedService.url) {
      window.open(this.selectedService.url, "_blank");
    }
  }

  getShowServicesInGroupSetting(selectedGroupId?: string) {
    this.isLoadingGroups = true;
    return this.http.get<boolean>(this.configService.ServiceGatewayBrokerAPIURL + 'Setting/GetShowServicesInGroupSetting').subscribe((res) => {
      this.showServicesInGroup = res;
      this.loadGroups(selectedGroupId);
    }, err => {
      this.isLoadingGroups = false;
    });
  }

  private loadGroups(selectedGroupId?: string) {
    this.isLoadingGroups = true;
    this.http.get<GroupForPublicViewModel[]>(this.configService.ServiceGatewayBrokerAPIURL + 'Group').subscribe((res) => {
      this.isLoadingGroups = false;
      this.serviceGroups = res;
      this.services = [];
      let index = 1;
      this.serviceGroups.forEach((group) => {
        // if (selectedGroupId == group.groupId) {
        //   this.tempSelectedServiceGroup = group;
        // }
        this.getGroupIcon(group);
        group.delay = index * 100 + "ms";
        index = index + 2;
      });
    }, err => {
      this.isLoadingGroups = false;
    });
  }

  private getGroupIcon(group: GroupForPublicViewModel) {
    this.getBase64Image(group.iconId).subscribe((res) => {
      group.icon = res;
    });

    let index = 1;
    group.services.forEach((service) => {
      this.getServiceIcon(service);
      service.delay = index * 100 + "ms";
      index = index + 2;
      this.services.push(service);
    });
  }

  private getServiceIcon(service: ServiceViewModel) {
    this.getBase64Image(service.iconId).subscribe((res) => {
      service.icon = res;
    });
  }
}
