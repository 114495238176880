<!-- Preloader -->
<div *ngIf="!isIE" id="preloader" style="display: flex;justify-content: center;align-items: center;">
  <!-- <div class="south-load"></div> -->
  <img src="/assets/images/loader.gif" />
</div>

<router-outlet *ngIf="!isIE"></router-outlet>

<div class="ie_box"  *ngIf="isIE">
  <div class="ie_center_logo"><img src="assets/images/logo.svg"></div>
  <div class="ie_center">المتصفح المستخدم قديم يرجى إستعمال متصفح حديث</div>
  <div class="ie_center_links"><a href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a> أو <a
      href="https://www.google.com/chrome">Google Chrome</a></div>
</div>