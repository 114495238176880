<footer class="footer article">
  <div class="container-fluid px-lg-5">
    <div class="row">
      <div class="col-md-9 py-5">
        <div class="row">
          <div class="col-md-4 mb-md-0 mb-4">
            <h5 class="footer-heading">{{'about_ministry' | translate}}</h5>
            <p style="margin-top: 15px; text-align: justify;">{{'about_ministry_body' | translate}}</p>
            <ul class="ftco-footer-social p-0 text-center">
              <li class="ftco-animate"><a href="https://www.facebook.com/AWQAFM" target="_blank" data-placement="top"
                  title="{{'Titel.Facebook' | translate}}"><span class="fab fa-facebook-f"></span></a></li>
              <li class="ftco-animate"><a href="https://twitter.com/AWQAFM" target="_blank" data-placement="top"
                  title="{{'Titel.Twitter' | translate}}"><span class="fa-brands fa-x-twitter"></span></a></li>
              <li class="ftco-animate"><a href="https://www.youtube.com/user/awqafministryqatar" target="_blank"
                  title="{{'Titel.Youtube' | translate}}"><span class="fab fa-youtube"></span></a>
              </li>
              <li class="ftco-animate"><a href="https://www.instagram.com/awqafm/" target="_blank" data-placement="top"
                  title="{{'Titel.Instagram' | translate}}"><span class="fab fa-instagram"></span></a></li>
            </ul>

            <br>
            <p class="copyright text-center">{{'FOOTER.COPYRIGHT' | translate}}</p>



          </div>
          <div class="col-md-8">
            <div class="row justify-content-center">
              <div class="col-md-12 col-lg-10">
                <div class="row">
                  <div class="col-md-4 mb-md-0 mb-4">
                    <h5 class="footer-heading">{{'FOOTER.MINISTRYFILE' | translate}}</h5>
                    <h6><ul class="list-unstyled">
                     
                        <li><a class="py-1 d-block" routerLink="/inner/about-ministry">{{'FOOTER.MINISTRYBRIEF' |
                            translate}}</a></li>
                        <li><a class="py-1 d-block"
                            routerLink="/inner/ministry-vision-and-message">{{'FOOTER.MINISTRYVISION' | translate}}</a>
                        </li>
                        <li><a class="py-1 d-block"
                            routerLink="/inner/ministry-department">{{'FOOTER.MINISTRYDEPARTMENTS' | translate}}</a>
                        </li>
                        <li><a class="py-1 d-block" routerLink="/inner/ministry-oc">{{'FOOTER.MINISTRYARCHITECTURE' |
                            translate}}</a></li>
                      
                    </ul></h6>
                  </div>
                  <div class="col-md-4 mb-md-0 mb-4">
                    <h5 class="footer-heading">{{'FOOTER.SITEGUIDE' | translate}}</h5>
                    <h6><ul class="list-unstyled">
                      
                        <li><a class="py-1 d-block" href="https://www.diwan.gov.qa" target="_blank">{{'FOOTER.Diwan' |
                            translate}}</a>
                        </li>
                        <li><a class="py-1 d-block" routerLink="/inner/related-links">{{'FOOTER.RELEATEDSITES' |
                            translate}}</a></li>
                        <li><a class="py-1 d-block" href="https://portal.www.gov.qa/wps/portal"
                            target="_blank">{{'FOOTER.GOVERNMONET' | translate}}</a></li>
                        <li><a class="py-1 d-block" href="https://mawared.qa"
                            target="_blank">{{'FOOTER.RESOURCES' | translate}}</a>
                        </li>
                     
                    </ul></h6>
                  </div>
                  <div class="col-md-4 mb-md-0 mb-4">
                    <h5 class="footer-heading">{{'FOOTER.INTERESTEDINFORMATION' | translate}}</h5>
                    <h6><ul class="list-unstyled">
                      
                        <li><a class="py-1 d-block" routerLink="/contactus">{{'FOOTER.CONTACTUS' | translate}}</a></li>
                        <li><a class="py-1 d-block" href="/e-services/ruqya-sharia">{{'FOOTER.REQAHSHAREI' |
                            translate}}</a></li>
                        <li><a class="py-1 d-block" href="e-services/search-mosques">{{'FOOTER.MOSQUESEARCH' |
                            translate}}</a></li>
                        <li><a class="py-1 d-block" href="https://outlook.office365.com"
                            target="_blank">{{'FOOTER.EMPLOYEEWINDOW' | translate}}</a></li>
                        <li><a class="py-1 d-block" href="/contactus">{{'FOOTER.WORKINGHOURS' | translate}}</a></li>
                        <li><a class="py-1 d-block" href="#">{{'FOOTER.TermsOfUse' | translate}}</a></li>
                     
                    </ul></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 py-md-5 py-4 aside-stretch-right pl-lg-5">
        <h5 class="footer-heading">{{'CONTACTUS.TITLE' | translate}}</h5>
        <form class="form-consultation" [formGroup]="contactUsForm" (ngSubmit)="sendMail()">
          <h6>
            <div class="form-group2" *ngIf="!hasNoRecords">
              <!--Subject Category-->
              <label for="SubjectCategory" style="display: none;">{{'CONTACTUS.SubjectCategory' | translate}}</label>
              <select formControlName="subjectCategory" id="subjectCategory" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.subjectCategory.errors }"
                (change)="onSelectedCategoryChange()">
                <option [ngValue]="null" disabled hidden>{{'CONTACTUS.SubjectCategory' | translate}}</option>
                <option *ngFor="let contactusSubjectCategory of contactusSubjectCategories" class="text-secondary"
                  [ngValue]="contactusSubjectCategory">
                  {{contactusSubjectCategory.name}}
                </option>
              </select>
              <div *ngIf="submitted && f.subjectCategory.errors" class="invalid-feedback">
                <div *ngIf="f.subjectCategory.errors.required">{{'CONTACTUS.SubjectCategoryRequired' | translate}}</div>
              </div>
            </div>

            <div class="form-group2">
              <label for="Name" style="display: none;">{{'CONTACTUS.Name' | translate}}</label>
              <input type="text" class="form-control" formControlName="name" id="Name"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" placeholder="{{'CONTACTUS.Name' | translate}}"
                title="{{'CONTACTUS.Name' | translate}}">
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">{{'CONTACTUS.NameRequired' | translate}}</div>
              </div>
            </div>
            <div class="form-group2">
              <label for="Mobile" style="display: none;">{{'CONTACTUS.MobileNumber' | translate}}</label>
              <input type="text" class="form-control" formControlName="mobile" id="Mobile"
                title="{{'CONTACTUS.MobileNumber' | translate}}" maxlength="15" numberonly
                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"
                placeholder="{{'CONTACTUS.MobileNumber' | translate}}">
              <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                <div *ngIf="f.mobile.errors.required">{{'CONTACTUS.MobileRequired' | translate}}</div>
                <div *ngIf="f.mobile.errors.maxlength">{{'CONTACTUS.MobileNumberMaxLength' | translate}}</div>
                <div *ngIf="f.mobile.errors.pattern">{{'CONTACTUS.MobilePattern' | translate}}</div>
              </div>
            </div>
            <div class="form-group2">
              <label for="Email" style="display: none;">{{'CONTACTUS.Email' | translate}}</label>
              <input type="text" class="form-control" formControlName="email" id="Email"
                title="{{'CONTACTUS.Email' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                placeholder="{{'CONTACTUS.Email' | translate}}">
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">{{'CONTACTUS.EmailRequired' | translate}}</div>
                <div *ngIf="f.email.errors.email">{{'CONTACTUS.EmailIncorrect' | translate}}</div>
              </div>
            </div>

            <div class="form-group2">
              <label for="emailSubject" style="display: none;">{{'CONTACTUS.EmailSubject' | translate}}</label>
              <input type="text" class="form-control" formControlName="emailSubject" id="emailSubject" maxlength="250"
                [ngClass]="{ 'is-invalid': submitted && f.emailSubject.errors }" placeholder="{{'CONTACTUS.EmailSubject' | translate}}"
                title="{{'CONTACTUS.EmailSubject' | translate}}">
              <div *ngIf="submitted && f.emailSubject.errors" class="invalid-feedback">
                <div *ngIf="f.emailSubject.errors.required">{{'CONTACTUS.EmailSubjectRequired' | translate}}</div>
              </div>
            </div>

            <div class="form-group2">
              <label for="Message" style="display: none;">{{'CONTACTUS.Message' | translate}}</label>
              <textarea name="" id="" cols="30" rows="3" formControlName="message" class="form-control" id="Message"
                title="{{'CONTACTUS.Message' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"
                placeholder="{{'CONTACTUS.Message' | translate}}"></textarea>
              <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                <div *ngIf="f.message.errors.required">{{'CONTACTUS.MessageRequired' | translate}}</div>
                <div *ngIf="f.message.errors.minlength">{{'CONTACTUS.MessageMinSize' | translate}}</div>
                <div *ngIf="f.message.errors.maxlength">{{'CONTACTUS.MessageMaxSize' | translate}}</div>
              </div>
            </div>

            <div class="form-group2">

              <button [disabled]="isLoading" type="submit" class="form-control submit px-3">
                <span class="spinner" *ngIf="isLoading">
                  <i class="fa fa-spinner fa-spin"></i>
                </span>
                {{'CONTACTUS.Send' | translate}}</button>
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="errorOccured">
              {{'ErrorHandling'|translate}}
            </div>
            <div class="alert alert-success p-1" role="alert" *ngIf="isSuccess">
              {{'CONTACTUS.SuccessMessage'|translate}}
            </div>
          </h6>
        </form>
      </div>
    </div>
  </div>
</footer>