
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ContactUsSubjectViewModel } from '../../model/contactussubject.model';
import { ConfigurationService } from 'src/app/common/services/config.service';

@Injectable({
    providedIn: 'root'
})

export class ContactUsService {

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        })
    };

    constructor(
        private http: HttpClient,private configService: ConfigurationService
    ) { }

    sendMail(contactObj) {
         return this.http.post(this.configService.ServiceURL + 'ContactUs/SendEmail/' , contactObj);
        
     }

     getContactUsSubjects(){
        return this.http.get<ContactUsSubjectViewModel[]>(this.configService.ServiceURL + 'ContactUs/AllContactUsSubjects' );
     }
}
