import { filter } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { Component, ChangeDetectorRef } from "@angular/core";
import { LoadingService } from "./common/services/loading.service";
import { SeoService } from "./common/services/seo.service";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { environment } from "src/environments/environment";
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "MinistryWebsite";
  public loading = false;
  isIE: boolean = false;

  constructor(
    private translate: TranslateService,
    private seoService: SeoService,
    private loadingService: LoadingService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    //  this.isIE = /*@cc_on!@*/false || !!document.documentMode;

    this.isIE = this.getBrowserName() == "ie";

    translate.setDefaultLang("ar");


    let selectedLanguage = localStorage.getItem(
      environment.selectedLanguageKey
    );

    if (selectedLanguage) translate.use(selectedLanguage);
    else translate.use("ar");

    if (translate.currentLang == "en") {
      let link: HTMLLinkElement = document.createElement("link");
      link.setAttribute("id", "ltrstyle");
      link.setAttribute("rel", "stylesheet");
      link.setAttribute("href", "./assets/css/ltr_style.css");
      document.head.appendChild(link);

    }

    this.loadingService.showLoaderEvent.subscribe((event) => {
      if (this.loading != true) {
        this.loading = true;
        this.cdRef.detectChanges();
      }
    });

    this.loadingService.hideLoaderEvent.subscribe((event) => {
      if (this.loading != false) {
        this.loading = false;
        this.cdRef.detectChanges();
      }
    });

    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      }

      if (event instanceof NavigationEnd) {
        this.loading = false;
      }
    });

    this.translate.get('MINISTRY_OF_AWQAF').subscribe((translated: string) => {
      this.titleService.setTitle(translated);

    });
    
    this.setMetaTags();
  }

  ngOnInit(): void {
    this.seoService.trackCanonicalChanges();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });    
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1:
        return "edge";
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        return "opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        return "chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "firefox";
      case agent.indexOf("safari") > -1:
        return "safari";
      default:
        return "other";
    }
  }

  setMetaTags() {   
    this.translate.get('META_DATA.title').subscribe((title: string) => {
      this.metaTagService.updateTag( 
        { name: 'title', content: title })
    });

    this.translate.get('META_DATA.keywords').subscribe((keywords: string) => {
      this.metaTagService.updateTag(
        { name: 'keywords', content: keywords })
    });    

    this.translate.get('META_DATA.description').subscribe((description: string) => {
      this.metaTagService.updateTag(
        { name: 'description', content: description })
    });

  }

}
