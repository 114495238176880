import { ReadPublicationPipe } from '../common/pipe/read-publication-file.pipe';
import { NgSelectModule } from "@ng-select/ng-select";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShowImagePipe } from "../common/pipe/show-image.pipe";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { LoadingService } from "../common/services/loading.service";
import {
  TranslateService,
  TranslateModule,
} from "@ngx-translate/core";
import { SeoService } from "../common/services/seo.service";
import { SafeHtmlPipe } from "../common/pipe/safe-html.pipe";
import { CookieService } from "ngx-cookie-service";
import { MailBodyTextDirective } from "../common/directives/mail.body.text.directive";
import { RequestInterceptor } from "../common/services/request.interceptor";
import { SafeUrlPipe } from "../common/pipe/safe-url.pipe";
import { ServicesComponent } from '../modules/services/component/services/services.component'
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { NumberOnlyDirective } from "../common/directives/number.only.directive";
import { DownloadPublicationFilePipe } from "../common/pipe/download-publication-file.pipe";
import { ShowPublicationCoverPipe } from '../common/pipe/show-publication-cover.pipe';

@NgModule({
  declarations: [
    ShowImagePipe,
    DownloadPublicationFilePipe,
    ReadPublicationPipe,
    ShowPublicationCoverPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    MailBodyTextDirective,
    ServicesComponent,
    NumberOnlyDirective
  ],
  imports: [CommonModule, HttpClientModule, TranslateModule, NgSelectModule, MatAutocompleteModule, ReactiveFormsModule],
  exports: [
    TranslateModule,
    ShowImagePipe,
    DownloadPublicationFilePipe,
    ReadPublicationPipe,
    ShowPublicationCoverPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    MailBodyTextDirective,
    NgSelectModule,
    ServicesComponent,
    MatAutocompleteModule,
    NumberOnlyDirective
  ],
  providers: [
    LoadingService,
    TranslateService,
    SeoService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
  ],
})
export class SharedModule { }
