
import { Directive, ElementRef, Renderer2 } from "@angular/core";

@Directive({
  selector: "[mailBody]"
})
export class MailBodyTextDirective {
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];
  constructor(elem: ElementRef, renderer: Renderer2) {

    renderer.listen(elem.nativeElement, "keydown", event => {
    if (event.key === '<' || event.key === '>')
        event.preventDefault();
});
  }
}
