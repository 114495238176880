import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DateServiceViewModel, PrayerTimesServiceViewModel } from '../model/hijri-date.model';
import { ConfigurationService } from '../../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class HijriDateService {

  selectedGregorianDate: Date;
  prayerTimes: PrayerTimesServiceViewModel;

  constructor(private http: HttpClient, private configService: ConfigurationService) { }

  getPrayerTimes() {
    let dtQueryString: string = "";
    if (this.selectedGregorianDate != null) {
      dtQueryString = "?date=" + this.selectedGregorianDate.toLocaleDateString('en-GB').replace(/\//g, "-");
    }

    return this.http.get<PrayerTimesServiceViewModel>(this.configService.ServiceGatewayBrokerAPIURL + 'PrayerTimes' + dtQueryString);
  }
}